* {
  box-sizing: border-box;
}

html,
body,
#root,
.container {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

canvas {
  opacity: 0;
  touch-action: none;
  animation: fade-in 2.5s ease 0.5s forwards;
}